import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  InputHTMLAttributes,
  InvalidEvent,
  ReactNode,
} from 'react';
import { colors, spacing } from '../../../../utils/styleguide';
import Typography from '../../text/Typography';
import { LabelContainer } from './LabelContainer';

type BaseTextProps = {
  value?: string;
  multiline?: boolean;
  hasFocus?: boolean;
  hasError?: boolean;
  hasSuccess?: boolean;
  maxLength?: number;
} & InputHTMLAttributes<HTMLElement>;

const BaseText = ({ value, multiline, hasFocus, hasError, hasSuccess, ...rest }: BaseTextProps) => {
  if (multiline) {
    return (
      <div
        className={rest.className}
        css={css`
          padding-right: 0 !important;
          padding-left: 0 !important;
          padding-bottom: 0 !important;
        `}
      >
        <textarea
          css={css`
            border: none !important;
            resize: none;
          `}
          rows={value ? value.split('\n').length : 1}
          {...rest}
          value={value}
        />
      </div>
    );
  }
  return <input {...rest} value={value} />;
};

const Input = styled(BaseText)`
  display: flex;
  color: ${({ disabled }) => (disabled ? colors.grey : colors.black)};
  outline: none;
  width: 100%;
  border: 1px solid ${colors.grey02};
  border-radius: 4px;
  background-color: ${({ disabled }) => (disabled ? colors.lightGray : colors.white)};
  padding: ${spacing[1]}px ${spacing[2]}px;
  height: ${({ multiline }) => (multiline ? 'auto' : '24px')};
  min-height: ${({ multiline }) => (multiline ? '96px' : '24px')};
  ${({ hasFocus, hasError, hasSuccess, disabled }) => {
    let borderColor = colors.grey02;
    if (hasFocus && !(hasError || hasSuccess || disabled)) {
      borderColor = colors.info;
    } else if (hasError) {
      borderColor = colors.error;
    } else if (hasSuccess) {
      borderColor = colors.success;
    } else if (disabled) {
      borderColor = colors.whiteTransparentSemi;
    }

    return (
      (hasFocus || hasError || hasSuccess || disabled) &&
      css`
        border-color: ${borderColor};
      `
    );
  }}
`;

export const getColors = ({
  disabled,
  showSuccess,
  successMessage,
  labelLifted,
  error,
}: CommonProps) => {
  const labelColor = colors.blackSecondary;
  const infoTextColor = colors.darkGrey;

  if (error) {
    return {
      labelColor: colors.error,
      infoTextColor: colors.error,
    };
  }

  if (!!successMessage || showSuccess) {
    return {
      labelColor: colors.success,
      infoTextColor: colors.success,
    };
  }

  if (disabled) {
    return {
      labelColor: colors.grey02,
      infoTextColor: colors.grey02,
    };
  }

  if (labelLifted) {
    return {
      infoTextColor: colors.info,
      labelColor: colors.info,
    };
  }

  return {
    infoTextColor,
    labelColor,
  };
};

type CommonProps = {
  disabled?: boolean;
  error?: string | ReactNode;
  successMessage?: string;
  showSuccess?: boolean;
  labelLifted?: boolean;
};

type InputBaseProps = CommonProps & {
  className?: string;
  instructions?: string;
  label?: string;
  type?: string;
  hasFocus?: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onInvalid?: (event: InvalidEvent<HTMLInputElement>) => void;
  multiline?: boolean;
  name?: string;
  required?: boolean;
  maxLength?: number;
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  style?: CSSProperties;
};

const InputBase = ({
  className,
  disabled,
  error,
  successMessage,
  showSuccess,
  instructions,
  hasFocus,
  labelLifted,
  label,
  type,
  value,
  onChange,
  onFocus,
  onBlur,
  onInvalid,
  multiline,
  name,
  required,
  maxLength,
  autoComplete,
  autoFocus,
  placeholder,
  style,
}: InputBaseProps) => {
  const { labelColor, infoTextColor } = getColors({
    disabled,
    showSuccess,
    successMessage,
    labelLifted,
    error,
  });

  return (
    <div className={className}>
      <label
        css={css`
          display: flex;
          position: relative;
        `}
      >
        <LabelContainer labelColor={labelColor} labelLifted={labelLifted}>
          {label}
        </LabelContainer>
        <Input
          type={type}
          hasFocus={hasFocus}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          hasError={!!error}
          hasSuccess={!!successMessage || showSuccess}
          disabled={disabled}
          multiline={multiline}
          name={name}
          required={required}
          maxLength={maxLength}
          onInvalid={onInvalid}
          {...(autoComplete && { autoComplete: autoComplete })}
          autoFocus={autoFocus}
          placeholder={placeholder}
          style={style}
        />
      </label>
      {(error || successMessage || instructions) && (
        <Typography
          variant="detailSmall"
          tag="div"
          color={infoTextColor}
          css={{ margin: `${spacing[0]}px ${spacing[2]}px 0` }}
        >
          {error ? error : successMessage || instructions}
        </Typography>
      )}
      {maxLength && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography variant="detail" color={colors.darkGray}>{`${
            value?.length || 0
          }/${maxLength}`}</Typography>
        </div>
      )}
    </div>
  );
};

export default InputBase;
