import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { ApiError } from 'next/dist/server/api-utils';
import {
  FooterBarClientProps,
  NavBarClientProps,
  processFooterBarData,
  processNavigationBarData,
} from '../common-components/contentful-elements/util/navigation';
import { LayoutProps } from '../types/LayoutTypes';
import { getByUniqueSlug } from './api/contentful';
import { UrlError, isApiError, isUrlError } from './error';
import log from './log';
import { captureException, getScopeContext } from './tracking/sentry/sentry';
import {
  MetaHeaderProps,
  transformMetaHeaderType,
} from '../common-components/contentful-elements/MetaHeader';
import { TypeFooter, TypeMetaHeader, TypeNavigationBar } from '../../@types/generated';
import config from './config';

type CommonAppProps = {
  layoutProps?: LayoutProps;
  metaHeader?: MetaHeaderProps;
  disableTrackingConsent?: boolean;
};

export interface NavigationBar extends TypeNavigationBar {
  fields: TypeNavigationBar['fields'] & { textColor?: string | null };
}

export type WithPageAppProps = {
  pageAppProps?: CommonAppProps & {
    isPPCPage?: boolean;
    navigationBar?: NavigationBar;
    footerBar?: TypeFooter;
  };
};

export type WithAppProps = {
  appProps: CommonAppProps & {
    error?: Error | ApiError | UrlError | { error: number };
    navigationBar?: NavBarClientProps;
    footerBar?: FooterBarClientProps;
  };
};

/**
 * Wrapper around `getServerSideProps` to fetch data common to all pages
 *
 * - MetaHeader
 * - NavigationBar
 * - FooterBar
 *
 * Note that those can also be customized by the pages themselves through:
 * - Contentful `page` props from `landingPage`
 * - `layoutProps`
 *
 * **Reminder:**
 * Server-side data is cached by default.
 * No user specific data should be cached.
 */
function withAppProps<T extends object>(
  getServerSidePropsFromPage?: (
    context: GetServerSidePropsContext,
  ) => Promise<GetServerSidePropsResult<T> & WithPageAppProps>,
) {
  return (async (ctx) => {
    try {
      // this caches the server-side data by default, remember to not cache user specific data
      if (config.ENV === 'staging') {
        // Opt out from cloudfront serving stale content if we are responding with a 5xx
        // This is to easier be able to catch errors in staging
        ctx.res.setHeader('Cache-Control', 'public, max-age=300, stale-if-error=0');
      } else {
        ctx.res.setHeader('Cache-Control', 'public, max-age=300');
      }
      const serverSidePropsFromPage = await getServerSidePropsFromPage?.(ctx);
      const pageProps =
        (serverSidePropsFromPage &&
          'props' in serverSidePropsFromPage &&
          (await serverSidePropsFromPage.props)) ||
        undefined;
      const { pageAppProps } = serverSidePropsFromPage || {};
      delete serverSidePropsFromPage?.pageAppProps; // remove custom pageAppProps to avoid interference with nextJs (error on additional keys)

      const getMetaHeader = async () => {
        if (!pageAppProps?.metaHeader) {
          return await getDefaultMetaHeader();
        }
        if (!pageAppProps.metaHeader.socialImageUrl) {
          const defaultMetaHeader = await getDefaultMetaHeader();
          return {
            ...pageAppProps.metaHeader,
            socialImage: defaultMetaHeader?.socialImageUrl,
            socialImageHeight: defaultMetaHeader?.socialImageHeight,
            socialImageWidth: defaultMetaHeader?.socialImageWidth,
          };
        }
        return pageAppProps.metaHeader;
      };

      const getNavigationBar = async () => {
        const showNavigationBar = !pageAppProps?.isPPCPage;
        if (!showNavigationBar) {
          return;
        }

        if (pageAppProps?.navigationBar) {
          return processNavigationBarData(pageAppProps?.navigationBar);
        }

        return await getDefaultNavigationBar();
      };

      const getFooterData = async () => {
        if (pageAppProps?.footerBar) {
          return processFooterBarData(pageAppProps?.footerBar);
        }

        return await getDefaultFooterBar();
      };

      const metaHeader = await getMetaHeader();
      const navigationBar = await getNavigationBar();
      const footerBar = await getFooterData();

      return {
        ...serverSidePropsFromPage,
        props: {
          ...pageProps,
          appProps: {
            layoutProps: pageAppProps?.layoutProps,
            metaHeader,
            navigationBar,
            footerBar,
            disableTrackingConsent: pageAppProps?.disableTrackingConsent,
          },
        },
      };
    } catch (error) {
      if ((isUrlError(error) || isApiError(error)) && error.status === 404) {
        // ignore
        log.debug({ err: error, url: ctx.req?.url }, `404 in withAppProps`);
      } else {
        captureException(error, getScopeContext({ ctx }));
        log.error({ err: error, url: ctx.req?.url }, `Error in withAppProps`);
      }

      if (ctx.res) {
        // @ts-expect-error FIXME: handle error types explicitly for improved error handling
        ctx.res.statusCode = error.status || 500;
      }
      const typedError = error as WithAppProps['appProps']['error'];
      const clientError =
        typedError && 'toClient' in typedError ? typedError.toClient() : typedError;
      return {
        props: {
          appProps: {
            ...(await getDefaultAppProps()),
            error: clientError,
          },
        },
      };
    }
  }) as GetServerSideProps<T & WithAppProps>; // Casting here as a workaround of a TS complicated issue: https://stackoverflow.com/questions/71917437/next-js-getserversideprops-custom-wrapper-typescript
}

const getDefaultMetaHeader = async () => {
  const metaHeader = await getByUniqueSlug<TypeMetaHeader>({
    contentType: 'metaHeader',
    slug: 'default-meta-header',
    include: 1,
  });

  return transformMetaHeaderType({ metaHeader });
};

const getDefaultNavigationBar = async () => {
  const navigationBarData = await getByUniqueSlug<TypeNavigationBar>({
    contentType: 'navigationBar',
    slug: 'default-nav-bar',
    include: 3,
  });
  return processNavigationBarData(navigationBarData);
};

const getDefaultFooterBar = async () => {
  const footerBarData = await getByUniqueSlug<TypeFooter>({
    contentType: 'footer',
    slug: 'default-footer',
    include: 3,
  });

  return processFooterBarData(footerBarData);
};

export const getDefaultAppProps = async (): Promise<WithAppProps['appProps']> => {
  const [metaHeader, navigationBar, footerBar] = await Promise.all([
    getDefaultMetaHeader(),
    getDefaultNavigationBar(),
    getDefaultFooterBar(),
  ]);
  return {
    metaHeader,
    navigationBar,
    footerBar,
  };
};

export default withAppProps;
