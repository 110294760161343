enum FormType {
  hubspot = 'hubspot',
}

type PrepareFormBodyProps = {
  type?: unknown;
  formData: Record<string, unknown>;
  args?: Record<string, unknown>;
};

export const prepareFormBody = ({ type, formData, ...args }: PrepareFormBodyProps) => {
  switch (type) {
    case FormType.hubspot: {
      const fields = Object.keys(formData).reduce(
        (acc: Record<string, unknown>[], item: string) => {
          if (formData[item]) {
            return [...acc, { name: item, value: formData[item] }];
          }
          return acc;
        },
        [],
      );
      return JSON.stringify({ fields, context: { ...args } });
    }
    default:
      return JSON.stringify({ ...formData, ...args });
  }
};
