import { Entry } from 'contentful';
import { breakpointQueries } from '../styleguide';
import { useMediaQuery } from './useMediaQuery';
import { TypeOrientedMediaSkeleton } from '../../../@types/generated';

export function useContentfulMediaOrientation(
  media?: Entry<TypeOrientedMediaSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>,
) {
  const [isTabletOrLarger, determined] = useMediaQuery(breakpointQueries.tablet);

  if (!media) return;

  if (!isTabletOrLarger && determined && media.fields.imagePortrait?.fields.file?.url) {
    return media.fields.imagePortrait;
  }

  return media?.fields.imageLandscape;
}
