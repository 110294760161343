import styled from '@emotion/styled';

type LabelContainerProps = {
  labelColor: string;
  labelLifted?: boolean;
};
export const LabelContainer = styled.div`
  pointer-events: none;
  z-index: 1;
  color: ${({ labelColor }: LabelContainerProps) => labelColor};
  position: absolute;
  background-color: white;
  padding: 0 4px;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: ${({ labelLifted }: LabelContainerProps) =>
    labelLifted ? 'translate(12px, -8px) scale(0.9)' : 'translate(12px, 12px) scale(1)'};
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;
